import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { IAuthState } from "../../modules/auth";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

const ResetPasswordBtn: React.FC = () => {
    const { loading } = useSelector<RootState>(({ auth }) => auth, shallowEqual) as IAuthState;
    const intl = useIntl();
    const history = useHistory();

    const handleResetPassword = () => {
        history.push("/password/reset");
    };

    return (
        <button
            type='button'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-yellow w-100 mt-2'
            onClick={handleResetPassword}
        >
            {!loading && (
                <span className='indicator-label no-transform' style={{textTransform: 'none'}}>{intl.formatMessage({ id: 'USER.EDIT.PASS_RESET' })}</span>
            )}
            {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'USER.WAIT' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
        </button>
    );
};

export default ResetPasswordBtn;
