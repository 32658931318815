import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {IReportDetails} from '../../../models/Reports'
import {SEOReportDeatils} from '../../../models/SEOReport'
import {selectors} from '../../../redux/ReportsRedux'
import ChartLinesWidget from '../../Widgets/ChartLinesWidget'
import TimeStatisticWidget from '../SeoWidgets/TimeStatisticWidget'
import {numSpaceFormater} from "../../../../../helpers/formater";

interface GSCComapreDataProps {
  print?: boolean
}

interface CurrentToPrevSessiosnChart {
  curSessions: number[]
  curDates: Date[]
  prevMonthDates: Date[]
  prevYearDates: Date[]
  prevMonthSessions: number[]
  prevYearSessions: number[]
}

const GSCComapreData: React.FC<GSCComapreDataProps> = ( props ) => {
  const intl = useIntl()
  const {
    SearchConsoleDates,
    analyticSessions,
    analyticSessionslastMonth,
    analyticSessionslastYear,
    SearchConsoleMonthBefore,
    SearchConsoleYearBefore,
  } = useSelector(selectors.getReportDetails) as SEOReportDeatils
  const {date} = useSelector(selectors.getReportData) as IReportDetails
  const [curToPrevSessionsChart, setCurToPrevSessionsChart] = useState<CurrentToPrevSessiosnChart>()
  const [currDate, setCurrDate] = useState<Date>()
  const [prevYear, setPrevYear] = useState<Date>()
  const [prevMonth, setPrevMonth] = useState<Date>()

  useEffect(() => {
    if (date) {
      setCurrDate(new Date(date))
      const curYear = new Date(date)
      const yearAgo = curYear.setFullYear(curYear.getFullYear() - 1)
      setPrevYear(new Date(yearAgo))

      const curMonth = new Date(date)
      const monthAgo = curMonth.setMonth(curMonth.getMonth() - 1)
      setPrevMonth(new Date(monthAgo))
    }
  }, [date])

  const prevMonthDatesCompare = (
    curDates: Date[],
    curParam: number[],
    prevDates: Date[],
    prevParam: number[]
  ) => {
    if (curDates.length !== prevDates.length) {
      if (curDates.length > prevDates.length) {
        let newCurDates: Date[] = []
        let newCurParam: number[] = []
        for (let i = 0; i < prevDates.length; i++) {
          newCurDates.push(curDates[i])
          newCurParam.push(curParam[i])
        }
        return {curDates: newCurDates, curParam: newCurParam, prevDates, prevParam}
      } else if (prevDates.length > curDates.length) {
        let newPrevDates: Date[] = []
        let newPrevParam: number[] = []
        for (let i = 0; i < curDates.length; i++) {
          newPrevDates.push(prevDates[i])
          newPrevParam.push(prevParam[i])
        }
        return {curDates, curParam, prevDates: newPrevDates, prevParam: newPrevParam}
      }
    }
    return {curDates, curParam, prevDates, prevParam}
  }

  useEffect(() => {
    if (analyticSessions && analyticSessionslastMonth && analyticSessionslastYear) {
      const {prevDates, prevParam, curDates, curParam} = prevMonthDatesCompare(
        analyticSessions.dates,
        analyticSessions.sessions,
        analyticSessionslastMonth.dates,
        analyticSessionslastMonth.sessions
      )
      setCurToPrevSessionsChart({
        curSessions: curParam,
        curDates: curDates,
        prevMonthDates: prevDates,
        prevYearDates: analyticSessionslastYear.dates,
        prevMonthSessions: prevParam,
        prevYearSessions: analyticSessionslastYear.sessions,
      })
    }
  }, [analyticSessions, analyticSessionslastMonth, analyticSessionslastYear])

  return (
    <div className='row g-3'>
      {SearchConsoleDates && SearchConsoleMonthBefore ? (
      <div className='col-6'>
        <div className={props.print ? 'px-3 py-5' : 'border px-3 py-5'}>
          <div className='row pb-5'>
            <div className='d-flex align-items-center'>
              <div className='title'>
                {currDate && prevMonth && (
                  <h3 className='fw-boldest text-sempai-green fs-2 text-uppercase'>
                    {'Miesiąc w ujęciu '}
                    {`${intl.formatDate(currDate, {
                      month: 'long',
                      year: 'numeric',
                    })} - ${intl.formatDate(prevMonth, {
                      month: 'long',
                      year: 'numeric',
                    })}`}
                  </h3>
                )}
              </div>
            </div>
          </div>

            <div className='row g-1 justify-content-between mb-5'>
              <div className='col-3'>
                <TimeStatisticWidget
                  label={'Wyświetlenia'}
                  value={numSpaceFormater(SearchConsoleDates.impressions_sum)}
                  currentValue={SearchConsoleDates.impressions_sum}
                  prevValue={SearchConsoleMonthBefore.impressions_sum}
                  type={null}
                />
              </div>
              <div className='col-3'>
                <TimeStatisticWidget
                  label={'Śr. CTR'}
                  value={(SearchConsoleDates.ctr * 100).toFixed(2) + '%'}
                  currentValue={SearchConsoleDates.ctr}
                  prevValue={SearchConsoleMonthBefore.ctr}
                  type={null}
                />
              </div>
              <div className='col-3'>
                <TimeStatisticWidget
                  label={'Suma kliknięć'}
                  value={numSpaceFormater(SearchConsoleDates.clicks_sum)}
                  currentValue={SearchConsoleDates.clicks_sum}
                  prevValue={SearchConsoleMonthBefore.clicks_sum}
                  type={null}
                />
              </div>
              <div className='col-3'>
                <TimeStatisticWidget
                  label={intl.formatMessage({id: 'AVG_POSITION'})}
                  value={Number(SearchConsoleDates.ave_position).toFixed(2)}
                  currentValue={SearchConsoleMonthBefore.ave_position}
                  prevValue={SearchConsoleDates.ave_position}
                  type={'number'}
                />
              </div>
            </div>

          <div className='row'>
            {SearchConsoleDates && (
              <ChartLinesWidget
                className='card-xl-stretch'
                chartId='seo-GSCComapreData-month'
                dateArray={SearchConsoleDates.dates}
                firstDataSet={SearchConsoleDates.impressions}
                secondDataSet={SearchConsoleMonthBefore.impressions}
                firstDataSetTitle={intl.formatMessage({id: 'IMPRESSIONS'})}
                secondDataSetTitle={intl.formatMessage({id: 'IMPRESSIONS_PREV_MONTH'})}
                stroke='smooth'
                height={250}
                colors={[getCSSVariableValue('--bs-sempai-green'), '#BEDFB8']}
                print={!!props.print}
                setBothAxisScaleAsMax={true}
              />
            )}
          </div>
        </div>
      </div>
      ): (
          <div className='col-6 d-flex h-100  justify-content-center align-items-center'>

          </div>
      )}
      {SearchConsoleDates && SearchConsoleYearBefore ? (
      <div className='col-6'>
        <div className={props.print ? 'px-3 py-5' : 'border px-3 py-5'}>
          <div className='row pb-5'>
            <div className='d-flex align-items-center'>
              <div className='title'>
                {currDate && prevYear && (
                  <h3 className='fw-boldest text-sempai-green fs-2 text-uppercase'>
                    {'Miesiąc w ujęciu '}
                    {`${intl.formatDate(currDate, {
                      month: 'long',
                      year: 'numeric',
                    })} - ${intl.formatDate(prevYear, {
                      month: 'long',
                      year: 'numeric',
                    })}`}
                  </h3>
                )}
              </div>
            </div>
          </div>

            <div className='row g-1 justify-content-between mb-5'>
              <div className='col-3'>
                <TimeStatisticWidget
                    label={'Wyświetlenia'}
                    value={numSpaceFormater(SearchConsoleDates.impressions_sum)}
                    currentValue={SearchConsoleDates.impressions_sum}
                    prevValue={SearchConsoleYearBefore.impressions_sum}
                    type={null}
                />
              </div>
              <div className='col-3'>
                <TimeStatisticWidget
                    label={'Śr. CTR'}
                    value={(SearchConsoleDates.ctr * 100).toFixed(2) + '%'}
                    currentValue={SearchConsoleDates.ctr}
                    prevValue={SearchConsoleYearBefore.ctr}
                    type={null}
                />
              </div>
              <div className='col-3'>
                <TimeStatisticWidget
                    label={'Suma kliknięć'}
                    value={numSpaceFormater(SearchConsoleDates.clicks_sum)}
                    currentValue={SearchConsoleDates.clicks_sum}
                    prevValue={SearchConsoleYearBefore.clicks_sum}
                    type={null}
                />
              </div>
              <div className='col-3'>
                <TimeStatisticWidget
                    label={intl.formatMessage({id: 'AVG_POSITION'})}
                    value={Number(SearchConsoleDates.ave_position).toFixed(2)}
                    currentValue={SearchConsoleYearBefore.ave_position}
                    prevValue={SearchConsoleDates.ave_position}
                    type={'number'}
                />
              </div>
            </div>

          <div className='row'>
            {SearchConsoleDates && SearchConsoleYearBefore && (
              <ChartLinesWidget
                className='card-xl-stretch'
                chartId='seo-GSCComapreData-year'
                dateArray={SearchConsoleDates.dates}
                firstDataSet={SearchConsoleDates.impressions}
                secondDataSet={SearchConsoleYearBefore.impressions}
                firstDataSetTitle={intl.formatMessage({id: 'IMPRESSIONS'})}
                secondDataSetTitle={intl.formatMessage({id: 'IMPRESSIONS_PREV_YEAR'})}
                stroke='smooth'
                height={250}
                colors={[getCSSVariableValue('--bs-sempai-green'), '#BEDFB8']}
                print={!!props.print}
                setBothAxisScaleAsMax={true}
              />
            )}
          </div>
        </div>

      </div>
      ):(
          <div className='col-6 d-flex h-100  justify-content-center align-items-center'>

          </div>
      )}
    </div>
  )
}

export default GSCComapreData
