import React, {useEffect, useState} from 'react'
import {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FullScreen, useFullScreenHandle} from 'react-full-screen'

type Props = {
  className: string
  chartId: string
  dateArray: Date[]
  firstDataSet: number[] | string[]
  secondDataSet: number[] | string[]
  firstDataSetTitle: string
  secondDataSetTitle: string
  stroke?: 'smooth' | 'straight' | 'stepline'
  height?: number
  colors?: string[]
  print?: boolean
  setBothAxisScaleAsMax?: boolean
}

const ChartLinesWidget: React.FC<Props> = ({
  className,
  chartId,
  dateArray,
  firstDataSet,
  secondDataSet,
  firstDataSetTitle,
  secondDataSetTitle,
  stroke = 'straight',
  height = 350,
  colors,
  print = false,
  setBothAxisScaleAsMax = false,
}) => {
  const handle = useFullScreenHandle()

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-500')
  const baseColor = getCSSVariableValue('--bs-sempai-green')
  const baseLightColor = getCSSVariableValue('--bs-light-success')
  const secondaryColor = getCSSVariableValue('--bs-dark')
  const secondaryLightColor = getCSSVariableValue('--bs-light-primary')
  const firstDataSetNumbers = (firstDataSet as (number | string)[]).map((val) => {
    const num = Number(val)
    return isNaN(num) ? 0 : num
  })
  const secondDataSetNumbers = (secondDataSet as (number | string)[]).map((val) => {
    const num = Number(val)
    return isNaN(num) ? 0 : num
  })

  let max = 0
  if (setBothAxisScaleAsMax) {
    const maxValue = Math.max(...firstDataSetNumbers, ...secondDataSetNumbers)
    max = Math.ceil(maxValue / 100) * 100
  }

  const yAxisConfig = {
    axisTicks: {
      show: true,
    },
    axisBorder: {
      show: true,
      color: secondaryColor,
    },
    labels: {
      style: {
        colors: labelColor,
      },
      formatter: (val: number) => {
        if (val === null || val === undefined || isNaN(val) || val <= 0) {
          return '0'
        }
        if (val >= 10000) {
          return `${(val / 1000).toFixed(0)}k`
        }
        if (val >= 100) {
          return `${Math.floor(val / 100) * 100}`
        }
        if (val < 1) {
          return val.toFixed(2) + ''
        }
        return val.toFixed(0) + ''
      },
    },
    ...(setBothAxisScaleAsMax && {min: 0, max: max, tickAmount: 5}),
  }

  const [chartOptions] = useState<ApexOptions>({
    chart: {
      id: chartId,
      fontFamily: 'inherit',
      height: 350,
      toolbar: {
        show: !print,
        tools: {
          download: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    stroke: {
      curve: stroke,
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      markers: {
        width: 38,
        height: 2,
        offsetY: -2,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: dateArray,
      axisBorder: {
        show: true,
        color: secondaryColor,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: labelColor,
        },
      },
    },
    yaxis: [
      yAxisConfig,
      {
        ...yAxisConfig,
        opposite: true,
      },
    ],
    colors: colors ? colors : [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      colors: [baseLightColor, secondaryLightColor],
      strokeColors: [baseLightColor, secondaryLightColor],
      strokeWidth: 3,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val: number) => {
          if (val < 1) {
            return val.toFixed(2) + ''
          }
          if (val <= 0) {
            return '0'
          }
          return val.toFixed(0) + ''
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          yaxis: [
            yAxisConfig,
            {
              ...yAxisConfig,
              opposite: true,
            },
          ],
        },
      },
    ],
  })

  useEffect(() => {
    if (handle.active) {
      ApexCharts.exec(
        'chart-lines-sem',
        'updateOptions',
        {
          ...chartOptions,
          responsive: [],
        },
        true
      )
    } else {
      ApexCharts.exec(
        'chart-lines-sem',
        'updateOptions',
        {
          ...chartOptions,
        },
        true
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handle])

  return (
    <>
      <div className={`session-group-chart px-5 ${className}`}>
        <div className='align-items-center border-0 clicks-group-chart'>
          <Button
            className='d-lg-none d-block btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1 d-print-none'
            variant='light'
            onClick={handle.enter}
          >
            <KTSVG path='/media/icons/duotone/General/Expand-arrows.svg' className='svg-icon-3' />
          </Button>
        </div>
        <div className='pt-xl-2 pb-xl-2 p-0' data-testid='click-revenue-to-date-chart'>
          <FullScreen handle={handle} className='bg-white'>
            {handle.active && (
              <div className='d-flex justify-content-end mb-5'>
                <Button
                  onClick={handle.exit}
                  variant='light'
                  className='d-lg-none d-block btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                >
                  <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-2x' />
                </Button>
              </div>
            )}
            <Chart
              series={[
                {
                  name: firstDataSetTitle,
                  type: 'line',
                  data: firstDataSetNumbers,
                },
                {
                  name: secondDataSetTitle,
                  type: 'line',
                  data: secondDataSetNumbers,
                },
              ]}
              options={chartOptions}
              height={height}
              width='100%'
            />
          </FullScreen>
        </div>
      </div>
    </>
  )
}

export default ChartLinesWidget
